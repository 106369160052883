.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.loader {
  --path: #2f3545;
  --dot: #021040;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}
.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  -webkit-transform: translate(-18px, -18px);
  transform: translate(-18px, -18px);
  -webkit-animation: dotRect var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}
.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}
.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  -webkit-animation: pathTriangle var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}
.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  -webkit-animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}
.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  -webkit-animation: pathCircle var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}
.loader.triangle {
  width: 48px;
}
.loader.triangle:before {
  left: 21px;
  -webkit-transform: translate(-10px, -18px);
  transform: translate(-10px, -18px);
  -webkit-animation: dotTriangle var(--duration)
    cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

@-webkit-keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}
@-webkit-keyframes dotTriangle {
  33% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  66% {
    -webkit-transform: translate(10px, -18px);
    transform: translate(10px, -18px);
  }
  100% {
    -webkit-transform: translate(-10px, -18px);
    transform: translate(-10px, -18px);
  }
}
@keyframes dotTriangle {
  33% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  66% {
    -webkit-transform: translate(10px, -18px);
    transform: translate(10px, -18px);
  }
  100% {
    -webkit-transform: translate(-10px, -18px);
    transform: translate(-10px, -18px);
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

html {
  -webkit-font-smoothing: antialiased;
}

@keyframes ldio-8g4i47htpex {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.ldio-8g4i47htpex div {
  position: absolute;
  animation: ldio-8g4i47htpex 0.8264462809917356s linear infinite;
  box-sizing: border-box !important;
}
.ldio-8g4i47htpex div:nth-child(1) {
  width: 35.86px;
  height: 35.86px;
  left: 27.709999999999997px;
  top: 104.32px;
  border-radius: 50%;
  background: #00133e;
}
.ldio-8g4i47htpex div:nth-child(2) {
  width: 53.79px;
  height: 53.79px;
  left: 45.64px;
  top: 68.46px;
  border-radius: 0 53.79px 0 0;
  border: 16.299999999999997px solid #0da8f0;
  background: none;
  animation-delay: 0.08264462809917356s;
  border-bottom: 0;
  border-left: 0;
}
.ldio-8g4i47htpex div:nth-child(3) {
  width: 89.64999999999999px;
  height: 89.64999999999999px;
  left: 45.64px;
  top: 32.599999999999994px;
  border-radius: 0 89.64999999999999px 0 0;
  border: 16.299999999999997px solid #ffbd59;
  background: none;
  animation-delay: 0.1652892561983471s;
  border-bottom: 0;
  border-left: 0;
}
.loadingio-spinner-radio-c3k54w5i8kw {
  width: 163px;
  height: 163px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-8g4i47htpex {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-8g4i47htpex div {
  box-sizing: content-box;
}
