/* @import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"); */
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
html,
body {
  background-color: #f2f7f8;
  font-family: "Heebo", sans-serif !important;
  padding-bottom: 40px;
}

html {
  scroll-behavior: smooth;
  --antd-wave-shadow-color: #021040;
}

::selection {
  color: #fff;
  background: #021040;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #e9eff4;
}

body::-webkit-scrollbar-thumb {
  background-color: #707993;
  border-radius: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
}

.serviceplist::-webkit-scrollbar {
  width: 6px;
  background-color: #e9eff4;
}

.serviceplist::-webkit-scrollbar-thumb {
  background-color: #707993;
  border-radius: 5px;
}

.serviceplist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
}

.ant-menu-dark.ant-menu-root::-webkit-scrollbar {
  width: 3px;
  background-color: #20233d;
}

.ant-menu-dark.ant-menu-root::-webkit-scrollbar-thumb {
  background-color: #e0ebf5;
  border-radius: 5px;
}

.ant-menu-dark.ant-menu-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #20233d;
}

ol.triporderlist::-webkit-scrollbar {
  width: 3px;
  background-color: #20233d;
}

ol.triporderlist::-webkit-scrollbar-thumb {
  background-color: #e0ebf5;
  border-radius: 5px;
}

ol.triporderlist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #e9eff4;
}

.tamilfont {
  font-family: "Meera Inimai", sans-serif;
}

a {
  text-decoration: none !important;
}

a.ant-btn {
  line-height: 35px !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.w-100 {
  width: 100%;
}

.width90 {
  /* max-width: 95%; */
  margin: auto;
}

.minmax-120 {
  min-width: 120px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.b0 {
  border: 0 !important;
  background: none !important;
}

.b-1 {
  border: 1px solid #e9eff4;
}

.padd0 {
  padding: 0;
}

.padd5 {
  padding: 5px;
}

.padd10 {
  padding: 10px;
}

.padd15 {
  padding: 15px;
}
.padd25 {
  padding: 25px;
}

.paddlr10 {
  padding: 0 10px;
}

.paddlr15 {
  padding: 0 15px;
}

.padd20 {
  padding-bottom: 20px !important;
  padding-top: 20px;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.my-1 {
  margin: 5px 0;
}

.my-2 {
  margin: 10px 0;
}

.border {
  border: 1px solid #ddd;
}

.bg-white {
  background-color: #fff !important;
}

/* .bg-grey { */
/* background-color: #f0f2f5 !important; */
/* } */
.text-green {
  color: #94e346 !important;
}

.text-red {
  color: #021040 !important;
}

.text-muted {
  color: #90a4ae;
}

.text-white {
  color: #fff;
}

.bg-red {
  background-color: #021040 !important;
  color: #fff;
}

.bg-dark {
  background-color: #20233d;
  color: #fff;
}

.bg-red h4,
.bg-red h5,
.bg-dark h4,
.bg-dark h5 {
  color: #fff;
}

.text-bold {
  font-weight: 600;
}

.profilename {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

div.logo {
  width: 120px;
  /* height: 31px; */
  padding: 25px 24px 25px 25px;
  float: left;
  color: #fff;
}

div.logo img {
  max-width: 100%;
  height: 30px;
  margin: 0;
}

.portal-open .ant-layout {
  display: none;
}

.portaldashboard {
  min-height: 100vh;
}

.ant-time-picker {
  width: 100%;
}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 0px solid #e8e8e8;
  box-shadow: 0 6px 8px rgba(102, 119, 136, 0.3),
    0 1px 2px rgba(102, 119, 136, 0.3);
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgb(255, 255, 255);
  background: #182343;
}

.ant-menu-horizontal .ant-menu-item {
  padding: 10px 20px;
}

.ant-menu-horizontal > .ant-menu-item.logomenu:hover {
  border-bottom: 2px solid transparent;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgb(255, 255, 255);
}

.profilemenu {
  float: right;
  padding: 14px 20px;
}

.langcomp .ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #ffffff;
  background: #021040;
  border-color: #021040;
  box-shadow: -1px 0 0 0 #021040;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #383a54;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #607d8b;
  background-color: #fff;
  border-color: #607d8b;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 10px;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #607d8b;
}

.ant-pagination-item-active a {
  color: #607d8b;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #607d8b;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #607d8b;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #607d8b;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #607d8b;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-btn {
  letter-spacing: 1px;
}

.ant-btn.disabled {
  pointer-events: none;
}

.ant-btn-default {
  border: none !important;

  font-size: 12px;
  border: none !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  background: #fcfcfd;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
}
.dash-orders {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border: none !important;
}
.dash-pending-orders {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border: none !important;
}
.dash-Previous-orders {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border: none !important;
}
.ant-btn-default:hover {
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), inset 0px -3px 0px #d6d6e7;
}

.ant-btn-primary {
  border: none !important;
  font-size: 12px;
  color: #fff;
  background-color: #021040;
  border-color: #021040;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px #02104066, 0px 7px 13px -3px #0210404d,
    inset 0px -3px 0px #001970;
  transition: box-shadow 0.25s ease-in;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #021040;
  border-color: #021040;
  box-shadow: 0px 2px 4px #02104066, inset 0px -3px 0px #001970;
}

.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #021040;
  border-color: #021040;
  box-shadow: 0px 2px 4px #02104066, inset 0px -3px 0px #001970;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #021040;
}

.ant-btn-secondary {
  border: none !important;
  height: 35px;
  color: #fff;
  font-size: 12px;
  background-color: #182343;
  border-color: #182343;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px #18234366, 0px 7px 13px -3px #1823434d,
    inset 0px -3px 0px #020311;
  transition: box-shadow 0.25s ease-in;
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: #fff;
  background-color: #15203e;
  border-color: #15203e;
  box-shadow: 0px 2px 4px #18234366, inset 0px -3px 0px #020311;
}

.ant-btn-secondary:active,
.ant-btn-secondary.active {
  color: #fff;
  background-color: #15203e;
  border-color: #15203e;
  box-shadow: 0px 2px 4px #18234366, inset 0px -3px 0px #020311;
}

.ant-form label {
  font-size: 13px;
  line-height: 18px;
}

.ant-checkbox-wrapper {
  font-weight: 500 !important;
  margin-bottom: 5px;
}

/* form input[type="text"],
form textarea {
  text-transform: uppercase;
} */

/* .ant-select-selection--multiple {
  min-height: 30px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
  max-height: 30px;
  overflow: hidden;
} */

.ant-input-password input {
  text-transform: none !important;
}

.loginpanel form input[type="text"] {
  text-transform: none !important;
}

.resetpasswordwrapper {
  max-width: 500px;
  margin: auto;
}

.serviceplist {
  min-height: 200px;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10px;
}

.panel.panel-default.loginpanel {
  min-width: 350px;
  padding: 35px 15px 15px;
  width: 100%;
  border: 0;
  max-width: 400px;
  border-radius: 0;
  box-shadow: none;
}

.panel-flex {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.search-flex {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

img.logologin {
  width: 90%;
}

.cover-full {
  height: 100vh;
  /* display: flex;
  justify-content: center;
  align-items: center;
  background-color: #182343;
  background-image: url(../img/login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: bottom; */
}

.img-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  box-shadow: inset -5px -5px 20px 8px #001970;
}

img.loginimg {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.ant-tabs-bar {
  background-color: #ffffff;
  margin: 0 !important;
}

.ant-tabs-content {
  padding-top: 0px;
  /* background-color: #f0f2f5; */
}

.ant-table-thead > tr {
  background: transparent;
}

.ant-tabs-nav-scroll {
  text-align: center;
}

.roundwrapper .ant-tabs-nav-scroll {
  text-align: left;
}

.ant-tabs-bar {
  margin: 0 0 0 0;
  border-bottom: 0px solid #e8e8e8;
  outline: none;
  -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-layout {
  background: #e9eff4;
}

.bg-grey {
  background: #e9eff4;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 15px 16px;
}

.chartwidth {
  width: 100%;
  height: 400px;
}

.sopformitem .ant-form-item {
  padding: 0px 15px;
}

.removebtn {
  margin-top: 6px;
}

.routeremovebtn {
  margin-top: 31px !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  height: 4px;
  background-color: #151e39;
  transform-origin: 0 0;
  border-radius: 8px 8px 0px 0px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #151e39;
  font-weight: 600;
}

.ant-tabs-nav .ant-tabs-tab:active {
  color: #021040;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #151e39;
  font-weight: 600;
}

.ant-tabs-nav .ant-tabs-tab:focus {
  outline: none;
}

.custommenu {
  display: flow-root;
  white-space: nowrap;
  border: 0;
  border-bottom: 0px solid #e8e8e8;
  box-shadow: 0 6px 8px rgba(102, 119, 136, 0.3),
    0 1px 2px rgba(102, 119, 136, 0.3);
  color: rgb(255, 255, 255);
  background: #182343;
}

ul.menulist {
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: 0px;
}

ul.menulist li {
  display: inline-flex;
  vertical-align: middle;
}

ul.menulist li a {
  padding: 20px 15px;
  color: rgb(255, 255, 255);
}

ul.menulist li.active {
  background-color: #021040;
}

ul.menulist li .profile {
  display: inline-block;
  margin-left: 20px;
}

ul.menulist li .langcomp {
  display: inline-block;
}

ul.menulist li:last-child {
  float: right;
}

.head-example {
  width: 33px;
  height: 33px;
  border-radius: 4px;
  background: #222546;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 9px;
}

.head-example:hover,
.head-example.ant-dropdown-open {
  background: #021040;
}

.ant-dropdown {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 7px 13px #f0f2f5;
}

.dropdown-header,
.dropdown-footer {
  padding: 10px 12px;
  position: relative;
}

.dropdown-header p {
  float: right;
  margin-bottom: 0;
  cursor: pointer;
}

.dropdown-header p:hover {
  color: rgb(59, 177, 8);
}

.dropdown-header h5 {
  margin-bottom: 0;
  font-weight: 600;
}

.dropdown-footer a:hover {
  color: #021040;
}

.dropdown-footer a {
  color: #021040;
  font-weight: 500;
}

.ant-dropdown li {
  height: auto !important;
  line-height: 24px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px 12px !important;
  border: none !important;
}

.ant-dropdown li a {
  border: none !important;
  height: auto !important;
  line-height: 25px;
}

.ant-dropdown li:hover {
  background-color: #e9eff4;
  background-image: linear-gradient(white, #fbfbfb);
}

.ant-dropdown li:hover a {
  color: #021040 !important;
}

.ant-dropdown li a {
  color: #222546 !important;
}

.ant-dropdown li::after,
.ant-dropdown li::before {
  display: none !important;
}

.ant-dropdown li a::after,
.ant-dropdown li a::before {
  display: none !important;
}

.ant-dropdown .ant-menu::after,
.ant-dropdown .ant-menu::before {
  display: none !important;
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #021040;
}

.bg-grey h4 {
  font-size: 18px;
  font-weight: 700;
  color: #454650;
  margin-bottom: 0;
}

.ant-card-cover img {
  border-radius: 2px 2px 0 0;
  max-height: 120px;
  min-height: 120px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 2px;
  padding: 12px;
  box-shadow: 0px 8px 7px 1px rgba(0, 0, 0, 0.04) !important;
}

.menu-mob {
  display: block;
  padding: 15px;
}

.menu-mob div.logo {
  width: auto;
  height: auto;
  padding: 0 5px;
  display: inline-block;
  color: #fff;
  float: none;
}

.menu-mob .profilemenu {
  float: right;
  padding: 0;
  display: flex;
}

ul.mob-menulist {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

ul.mob-menulist li a {
  color: #383a54;
}

ul.mob-menulist li.active a {
  color: #fff;
}

ul.mob-menulist li.active {
  background-color: #021040;
}

.mobmenubtn {
  padding: 0 10px;
}

.gm-style-iw button {
  display: none !important;
}

.heightorder {
  height: calc(100vh - 133px);
  overflow-y: scroll;
}

@media only screen and (max-width: 767px) {
  .heightorder {
    height: auto;
    overflow-y: auto;
  }
}

.ant-card-bordered {
  border: 1px solid #f1f1f1;
}

.ordersearch.sticky {
  position: sticky;
  top: 0;
  padding: 0;
  z-index: 100;
  background-color: #e9eff4;
}

.ordersearch input {
  height: 60px;
  border: 0;
  outline: 0;
  padding: 15px;
  border-radius: 0 !important;
  background-color: #e9eff4;
}

.ordersearch input:focus {
  height: 60px;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.o-hidden {
  overflow: hidden;
}

.heightorder .ant-tabs-top-bar {
  padding: 0;
}

.heightorder .ant-tabs-nav .ant-tabs-tab {
  margin-right: 0 !important;
}

thead.ant-table-thead {
  background-color: beige;
}

.ant-table-body td,
.ant-table-body th {
  border: 1px solid #e8e8e8;
}

.ant-table-tbody > tr:hover > td {
  background: #f0f2f5 !important;
}

.ant-layout-sider-trigger {
  background: #232645 !important;
  color: #ffffff;
}

.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 25px;
  /* 39.9999px */
  white-space: nowrap;
  text-align: left !important;
  vertical-align: middle;
}

.ant-input-number-disabled {
  color: rgb(0 0 0);
  background-color: #fbfbfb;
  cursor: not-allowed;
  opacity: 1;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(24, 35, 67, 0.33);
}

ul.dropdown-menu.open {
  display: block;
  border-radius: 2px;
}

ul.dropdown-menu {
  background-color: #182343;
}

ul.dropdown-menu li {
  display: block;
}

ul.dropdown-menu li:last-child {
  float: none;
}

ul.dropdown-menu li a {
  padding: 10px 15px;
  color: rgb(255, 255, 255);
}

div.dropdown-toggle {
  cursor: pointer;
  padding: 20px 15px;
  color: rgb(255, 255, 255);
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #021040;
  outline: 0;
}

.ant-layout-sider {
  position: fixed;
  min-width: 0;
  background: #20233d;
  transition: all 0.2s;
  height: 100vh;
  border-right: 0px solid #20233d;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  padding-bottom: 60px;
}

.ant-menu-dark.ant-menu-root {
  overflow-y: scroll;
  height: calc(100vh - 64px);
  padding-top: 15px;
}
.ant-menu-submenu-title {
  text-transform: uppercase;
  font-weight: 700;
}
.ant-layout-sider.ant-layout-sider-collapsed
  .ant-menu-dark.ant-menu-root:hover {
  /* overflow-y: hidden !important; */
  padding-bottom: 50px;
}

.ant-layout-sider.ant-layout-sider-collapsed
  .ant-menu-dark.ant-menu-root::-webkit-scrollbar {
  display: none;
}

.ant-layout-sider.ant-layout-sider-collapsed .ant-menu-dark.ant-menu-root {
  padding-bottom: 50px;
}

.ant-layout-sider-children {
  height: 100%;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  margin-left: 250px;
  transition: 0.25s;
}

.ant-layout-sider-collapsed ~ .ant-layout {
  margin-left: 80px !important;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #179bd7;
}

.ant-menu-item > a:hover {
  color: #179bd7;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #179bd7;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #179bd7;
  border-right: 2px solid #179bd7;
}
.ant-menu-submenu-open {
  background-color: #179bd7 !important;
  border-right: 2px solid #179bd7;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
/* .ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected, */
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: #179bd7;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fff;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #fff !important;
}

.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-arrow::before,
.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-arrow::after,
.ant-menu-submenu-open.ant-menu-submenu-active .ant-menu-submenu-arrow::before,
.ant-menu-submenu-open.ant-menu-submenu-active .ant-menu-submenu-arrow::after {
  background: #021040 !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #fff0f0;
}

.ant-layout-header {
  height: 64px;
  padding: 0 15px;
  line-height: 64px;
  background: #ffffff;
  /* border-bottom: 1px solid #e8e8e8; */
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #607d8b;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(96, 125, 139, 0.2);
  box-shadow: 0 0 0 2px #607d8b45;
}

.ant-select-selection:hover {
  border-color: #607d8b;
  border-right-width: 1px !important;
}

.ant-select-dropdown-menu-item:hover {
  background-color: #607d8b21;
}

.dimensiontitle {
  font-size: 14px;
  background-color: beige;
  padding: 15px 30px;
  margin: 0 -30px 20px -30px;
  font-weight: bold;
}

.servicepanel {
  min-height: 240px;
}

.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected:hover
  .ant-select-selected-icon {
  display: inline-block;
  color: #021040;
}

.servicepanel h5 {
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
}

.ant-modal-body {
  padding: 15px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

/* .ant-form-item-label {
  line-height: 10px;
} */
div.logo img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  max-height: 32px;
}

div.logo {
  width: 100%;
  padding: 16px 24px 16px 25px;
  color: #fff;
  text-align: center;
  background-color: #021040;
  /* margin-bottom: 15px; */
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 11px;
  right: -50px;
  width: 40px;
  height: 40px;
  color: #182343;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #f5f5dc;
  border-radius: 4px;
}

.ant-layout-sider-zero-width-trigger:hover {
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #fff;
}

.ant-menu-sub li {
  padding-left: 32px !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #0d152f;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

.ant-menu-dark.ant-menu-inline {
  padding-bottom: 50px;
}

.ant-input-number {
  width: 100%;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-modal {
  top: 20px !important;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: #021040;
}

.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 15px;
}

.pincodemodal {
  width: 100% !important;
  max-width: 700px;
}

.pincodemodal .ant-form-item {
  width: 50%;
  display: inline-block;
  padding: 0 10px;
}

.searchblock input {
  text-align: center !important;
}

.searchblock {
  margin-bottom: 20px;
}

.infoblock h4 {
  color: #021040;
  font-weight: 600;
}

.infoblock h4.status {
  color: #66bb6a;
}

.infoblock p {
  color: #707993;
  margin-bottom: 30px;
}

.infoblock .padd0 p.estimateddel {
  color: #707993;
  margin-bottom: 0px;
}

.infoblock .padd0 p.estimateddel span {
  color: #021040;
  font-weight: 500;
}

.Statusblock {
  padding: 15px;
}

.Statusblock h5 {
  font-size: 16px;
  color: #707993;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #021040;
}

.ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: beige;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #021040;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-steps-dot .ant-steps-item-icon {
  width: 20px !important;
  height: 20px !important;
  line-height: 10px;
  margin-left: 64px;
}

.titleblock h5 {
  font-weight: 600;
  margin-bottom: 0;
}

.ant-steps-dot .ant-steps-item-tail {
  top: 4px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}

.ant-steps-dot .ant-steps-item-title {
  line-height: 1.5;
  font-weight: 600;
  color: rgb(21, 30, 57);
}

.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
  color: rgb(21, 30, 57);
}

.trackblock table {
  width: 100%;
}

.trackblock td {
  width: 50%;
  padding: 10px 15px;
  border: 0.5px solid beige;
}

.trackblock tr td:first-child {
  background-color: beige;
  color: #151e39;
}

.trackblock {
  margin-bottom: 30px;
}

.paddr0 {
  padding-right: 0px;
}

.paddl0 {
  padding-left: 0px;
}

.statustable th {
  color: #151e39;
  padding: 10px 15px;
  border: 0.5px solid beige;
}

.statustable tr th:first-child {
  background-color: beige;
  color: #151e39;
}

.statustable td {
  width: auto;
  padding: 10px 15px;
  border: 0.5px solid beige;
}

.ant-form-item.col-sm-6 {
  padding: 0 15px;
}

.bg-beige {
  background-color: beige;
}

.bg-beige h5 {
  margin-bottom: 0;
}

.paddrl15 {
  padding: 0 15px;
}

.padd0 {
  padding: 0;
}

.bg-white.ant-form-item {
  padding: 0 !important;
}

.bg-white.ant-form-item.col-md-6 {
  width: 48.75%;
  margin-right: 1.25%;
}

.bg-white.ant-form-item.col-md-6:nth-child(3),
.bg-white.ant-form-item.col-md-6:nth-child(5) {
  width: 50%;
  margin-right: 0px;
}

.ant-layout-sider-collapsed div.logo img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  max-height: 32px;
}

.ant-table-placeholder {
  position: relative;
  z-index: 1;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.ant-input[disabled] {
  color: rgb(79, 79, 79);
  background-color: #ffffff;
  cursor: not-allowed;
  opacity: 1;
  font-weight: 400;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px solid #20233d;
}

.ant-layout-sider-collapsed .ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #20233d !important;
}

.notisound {
  display: none;
}

.ant-form-extra {
  padding-top: 4px;
  font-size: 12px;
}

.a {
  cursor: pointer;
}

.profilemenulist {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  padding: 10px 0;
  min-width: 150px;
}

.profilemenulist li p {
  margin-bottom: 0;
  cursor: pointer;
}

.profilemenulist li p:hover {
  color: #021040;
}

.profilemenulist li i {
  margin-right: 10px;
}

.ant-avatar-image {
  cursor: pointer;
}

ul.notilist {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  min-width: 275px;
}

ul.notilist li {
  padding-left: 0;
  line-height: 20px;
  border-bottom: 1px solid #f1f1f1 !important;
}

ul.notilist li.active {
  border-left: 1px solid #021040 !important;
}

ul.notilist li.notititle {
  padding: 5px 0;
  line-height: 30px;
  background-color: #20233d;
  color: #fff;
  font-size: 13px;
}

.time {
  font-size: 10px;
  color: gray;
}

.viewall {
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}

.viewall:hover {
  text-align: center;
  font-size: 13px;
  color: #021040;
  background-color: #fff !important;
}

img.notiemptyimg {
  max-width: 50%;
  margin: 0 auto;
}

.notiempty {
  padding: 30px 15px !important;
}

.notiempty img {
  margin-bottom: 20px;
}

.noticon {
  margin-right: 20px;
}

img.notfoundimg.mb30 {
  width: 100%;
  max-width: 250px;
}

ul.quicklinks {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  min-width: 275px;
  max-width: 300px;
}

ul.quicklinks li {
  padding-left: 0;
  padding: 10px !important;
  line-height: 20px;
  border: 0.5px solid #f1f1f15e !important;
  display: inline-block;
  width: 33.33%;
  font-size: 10px;
  height: 60px !important;
  vertical-align: top;
  color: #20233d;
  text-align: center;
}

ul.quicklinks .anticon {
  color: #021040;
  font-size: 14px;
}

ul.quicklinks li.quicklinktitle {
  height: auto !important;
  padding: 5px 12px !important;
  line-height: 30px;
  background: #20233d !important;
  color: #fff;
  font-size: 13px;
  display: block !important;
  width: 100%;
  text-align: left;
}

ul.quicklinks li.quicklinktitle:hover {
  background: #20233d !important;
}

.ant-calendar-picker {
  width: 100% !important;
}

.customerform h5 {
  padding: 15px;
}

.tagselect .ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0px;
}

.tagselect .ant-select-selection--multiple .ant-select-selection__choice {
  max-width: 90%;
}

.tagselect .ant-select-selection__choice__remove {
  display: none;
}

.ant-form-explain {
  display: block;
  font-size: 12px;
  position: absolute;
}

.ant-form-item-with-help {
  margin-bottom: 24px;
}

.ant-select-disabled .ant-select-selection {
  background: #ffffff;
  cursor: not-allowed;
}

.ant-select-disabled {
  color: rgb(0 0 0 / 75%);
}

.paymentproof {
  max-height: 150px;
}

#nprogress .bar {
  background: #021040 !important;
}

#nprogress .spinner-icon {
  border-top-color: #021040 !important;
  border-left-color: #021040 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #021040, 0 0 5px #021040 !important;
}

.navBlock .titleHeader {
  /* font-family: "Montserrat"; */
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #242424;
}

.navBlock:hover .titleHeader {
  color: #021040;
}

.navBlock .ant-card-body {
  padding: 28px 10px;
}

.navBlock img {
  height: 60px;
  background-color: #ffffe7;
  border-radius: 14px;
  padding: 10px;
  margin: 20px;
}

.navBlock:hover {
  /* border: 1px solid #00000029; */
  box-shadow: 0px 14px 14px #00000014;
  background-image: linear-gradient(white, #fbfbfb);
}

.d-inline-block {
  display: inline-block;
  vertical-align: top;
}

.samplefilebtn:hover {
  color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.samplefilebtn {
  color: #4caf50 !important;
}

.excelicon {
  color: #4caf50;
}

ul.hubassetlists {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

ol.triporderlist {
  padding-left: 15px;
  margin-bottom: 0;
  overflow-y: scroll;
  height: 400px;
}

ol.triporderlist p {
  margin-bottom: 0;
}

ol.triporderlist .ant-btn-link {
  padding: 0 5px;
}

ol.triporderlist li {
  border: 1px solid #e9eff4;
  padding: 10px;
  border-bottom: 0;
  box-shadow: 2px 2px 4px 0px #0000000f;
}

ul.hubassetlists li {
  border: 1px solid #e9eff4;
  padding: 10px;
  margin: 0 10px 10px 0;
  box-shadow: 2px 2px 4px 0px #0000000f;
}

ol.triporderlist li {
  border: 1px solid #e9eff4;
  padding: 0 15px 15px;
  border-bottom: 0;
}

ol.triporderlist li:hover {
  background-color: beige;
  transition: all 0.25s;
}

ul.hubassetlists li:last-child,
ol.triporderlist li:last-child {
  border-bottom: 1px solid #e9eff4;
}

ul.hubassetlists li p {
  margin-bottom: 0px;
}

ul.hubassetlists li h5 {
  font-weight: 600;
}

.ant-card-body {
  padding: 15px;
  zoom: 1;
}

h4 {
  font-size: 18px;
  color: #232645;
  font-weight: 600;
}

.notfounddiv {
  background-color: #fff;
  margin: 15px;
  padding: 50px 15px;
  text-align: center;
}

.notfounddiv img {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;
}

a {
  color: #3f51b5;
}

.resetform .ant-form-item {
  margin-bottom: 20px;
}

.ant-input:focus {
  border-color: #607d8b !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px #607d8b47 !important;
}

.ant-input:hover {
  border-color: #607d8b !important;
}

.passwordinput .ant-form-item-children-icon {
  right: 30px !important;
}

input:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
  background-color: #fff !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

span.ant-avatar.ant-dropdown-trigger.ant-dropdown-open.proicon.ant-avatar-circle.ant-avatar-image {
  cursor: pointer;
}

.hubwidgets h5 {
  margin-bottom: 15px;
}

.hubwidgets h4 {
  margin-bottom: 0px;
  font-size: 18px;
}

p.tripaddress {
  color: #878787;
  margin-bottom: 5px !important;
}

p.triptitle {
  font-size: 15px;
  font-weight: 600;
  color: #021040;
}

p.triptitle span {
  font-size: 15px;
  font-weight: 600;
  color: #0d152f;
}

p.tripdinfo {
  font-size: 13px;
}

.ant-tag.ant-tag-has-color.originhubi {
  margin-top: 15px;
  color: #212121;
  border: 1px solid #f9f9f9;
  box-shadow: 0px 1px 6px #c7c7c7;
  margin-bottom: 15px;
}

p.tripinfo span {
  margin-left: 5px;
}

.p-3 {
  padding: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.podimgwrapper {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px;
  display: inline-block;
  position: relative;
  background-color: #f9f9f9;
  box-shadow: 0px 6px 10px #c7c7c7;
}

.podimgwrapper:hover {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px;
  display: inline-block;
  position: relative;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px #c7c7c7;
}

.podimgwrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button {
  outline: none !important;
}

.btn-optimize {
  padding: 0 10px 0 5px;
}

.btn-optimize i {
  color: #021040;
}

.dragbtn {
  padding: 0 8px;
  cursor: grab;
}

.btn-optimize.ant-btn-loading {
  padding: 0 10px 0 24px;
}

.ordersearch div.form-item {
  display: inline-block;
  padding: 10px;
}

.searchblock .ant-form-horizontal .ant-form-item {
  /* display: inline-block; */
  padding: 10px;
  margin-bottom: 0;
  /* vertical-align: baseline; */
}

.ant-form-horizontal .ant-form-item {
  padding: 0 10px;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.locationfield .ant-input-number {
  display: inline-block;
  width: 45%;
  margin-right: 5%;
}

.hubincharge {
  padding: 3px 10px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 0;
  box-shadow: 0px 2px 3px 0px #0000001f;
  border: 1px solid #f1f1f1;
}

.hubinchargeinfo {
  padding: 0px;
}

.hubinchargeinfo p.hiname {
  color: #212121;
  margin-bottom: 5px;
  font-weight: 600;
}

.hubinchargeinfo p.hiemail {
  color: #585858;
  margin-bottom: 5px;
}

.hubinchargeinfo p.hiphone {
  color: #585858;
  margin-bottom: 5px;
}

.allnotilist {
  width: 100%;
  max-width: 450px;
  padding-left: 0;
  margin: auto;
}

.allnotilist li {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 3px 5px #5858581c;
  list-style-type: none;
  padding: 15px;
}

.allnotilist li.active {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 3px 5px #5858581c;
  list-style-type: none;
  padding: 15px;
  border-left: 2px solid #021040;
}

.docketscanlabel {
  padding: 0 !important;
  border: 1px solid #f1f1f1;
}

.docketscanlabel > div:first-child {
  background-color: beige;
  width: 100%;
  padding: 5px 15px 0;
  display: block;
}

.boxesrow {
  margin: 0 0px 15px 0px;
  border-bottom: 1px solid #f1f1f1;
}

.boxesrow:last-child {
  border-bottom: 0px solid #f1f1f1;
  margin-bottom: 0;
}

.boxesrow .ant-form-item {
  margin-bottom: 5px;
}

.deliverydetails {
  padding: 0 15px;
  border: 0;
  /* background: linear-gradient(61deg,#fff,#f1f1f3); */
}

.deliverydetails .col-xs-12 {
  border: 0.25px solid #f1f1f1;
  padding: 10px;
  /* background: linear-gradient(61deg,#fff,#f1f1f3); */
}

.ConnoteEnquiry .ant-tabs-tabpane {
  padding: 0 5px;
}

.ConnoteEnquiry .ant-card-body label {
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}

.ConnoteEnquiry .ant-card-body label:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  border-bottom: 0px solid #f5f5f5;
}

.ConnoteEnquiry .ant-card-body {
  padding: 10px;
}

.ConnoteEnquiry .ant-tabs-nav-scroll {
  text-align: left;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #182343;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border-radius: 0;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
  border-bottom: 1px solid #e8e8e8;
}

.processheader {
  display: block;
  position: relative;
  border-top: 1px solid #f5f5f5;
  padding: 15px 0;
  margin-top: 10px;
}

.processheader p {
  display: inline-block;
}

.editprocess {
  float: right;
  top: 0;
}

.servicepanel {
  height: 100%;
}

.servicepanel img {
  height: 60px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .col-md-offset-1 {
    margin-left: 15px !important;
  }
}

@media (max-width: 767px) {
  .ant-steps {
    overflow-x: scroll;
    overflow-y: visible;
    padding-top: 5px;
  }

  .paddl0 {
    padding-left: 15px;
  }

  .paddr0 {
    padding-right: 15px;
  }
}

.cus-bg-color {
  background-color: #021040;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
}

.pickupupload .ant-upload.ant-upload-select-picture-card {
  width: 104px !important;
}

.pickupmodal {
  width: 100% !important;
  max-width: 800px;
}

.deliverymodal {
  width: 100% !important;
  max-width: 1200px;
}

/* width
.ant-table-scroll .ant-table-body::-webkit-scrollbar {
  height: 0px;
}

.ant-table-scroll .ant-table-body:hover::-webkit-scrollbar {
  height: 10px;
}

Track
.ant-table-scroll .ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

Handle
.ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb {
  background: #707993;
}

Handle on hover
.ant-table-scroll .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #707993;
} */

.ant-layout-content {
  padding-bottom: 15px;
}

.printwrapper {
  position: relative;
  width: 100%;
  background-color: #fbfbfb;
  padding: 15px;
  margin-top: 15px;
}

.printwrapper a {
  background-color: #021040;
  color: #fff;
  padding: 13px 15px;
  border-radius: 4px;
  width: fit-content;
  max-width: 120px;
  transition: all 0.25s;
  text-decoration: none;
}

.printwrapper a:hover {
  background-color: #f20510;
}

.print-source {
  display: none;
  background-color: red;
}

body > .print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;
    background-color: green;
  }
}

.clabel {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

span.cls_002 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_002 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-align: left;
}

span.cls_003 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

div.cls_003 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

span.cls_009 {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: rgb(255, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_009 {
  font-family: "Open Sans", sans-serif;
  font-size: 18.1px;
  color: rgb(255, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

span.cls_004 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

div.cls_004 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

span.cls_010 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_010 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

span.cls_005 {
  font-family: "Open Sans", sans-serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

div.cls_005 {
  font-family: "Open Sans", sans-serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
}

span.cls_006 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_006 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

span.cls_007 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_007 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

span.cls_008 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_008 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

span.cls_011 {
  font-family: "Open Sans", sans-serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

div.cls_011 {
  font-family: "Open Sans", sans-serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

span.cls_012 {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

div.cls_012 {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

.roundtrippanel {
  box-shadow: 0px 4px 5px 2px rgb(0 0 0 / 2%);
  border: 1px solid #f5f5f5;
  margin-bottom: 15px;
}

.rtstatuslink {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #f5f5f5;
  color: #333 !important;
  text-align: left;
  cursor: pointer;
  transition: all 0.25s;
  white-space: nowrap;
}

.rtstatuslink:hover {
  background-color: #f9f9f9;
}

.rtstatuslink .anticon-down {
  float: right;
  margin-top: 5px;
}

.trstatuslist {
  padding: 12px 0px 0px;
}

.trstatuslist li:hover {
  background-color: transparent;
}

.trstatuslist li:last-child .ant-timeline-item-tail {
  display: none !important;
}

.trstatuslist .ant-timeline-item-tail {
  left: 16px;
}

.trstatuslist .sstatus {
  color: #242424;
  margin-bottom: 0;
  margin-top: 5px;
}

.trstatuslist .stime {
  color: #bfbfbf;
  margin-bottom: 0;
  font-size: 12px;
}

.inquirydrawer .ant-drawer-body {
  padding: 0 0 0 0px;
}

.invoiceinquiry {
  height: calc(100vh - 145px);
  width: 100%;
  display: block;
  overflow-y: scroll;
  background-color: #f9f9f9;
}

.invoiceinquiry ul {
  height: 100%;
  list-style-type: none;
  padding: 15px 10px 0px 10px;
  margin-bottom: 10px;
}

.invoiceinquiry ul li {
  display: block;
}

.invoiceinquiry ul li.inq-right .inqmsg {
  /* float: right; */
  background-color: #fff;
}

.invoiceinquiry ul li .inqmsg {
  margin-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 15px;
  box-shadow: 0px 2px 4px #0000001c;
  border-radius: 4px;
  /* max-width: 90%; */
  width: 100%;
  background-color: beige;
}

.inqmsguser {
  color: #021040;
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

.inqmsgtime {
  color: #1a1a1a;
  display: flex;
  margin-top: 5px;
  font-size: 12px;
}

.inquiryinfo h5 {
  margin-bottom: 0;
}

.inquiryinfo p {
  margin-bottom: 5px;
  font-size: 12px;
}

.inquiryinfo hr {
  border-color: #f5f5f5;
}

.inqformfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
}

.inqformfooter .ant-btn-primary {
  margin-left: 5px;
}

.inqformfooter textarea {
  resize: none;
}

.reconheader {
  border-top: 1px solid #e8e8e8;
}

.invoiceboxesrow {
  margin: 0 0px 10px 0px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 10px #0000000d;
  padding: 0 !important;
  padding-top: 10px !important;
}

.invoiceboxtitle {
  margin-bottom: 0px;
  padding: 15px;
  background-color: beige;
  margin-left: -10px;
  margin-right: -10px;
  /* margin-top: -10px; */
}

.invoicebuilder {
  padding: 10px 0;
}

.ant-btn-link {
  color: #20233d;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-empty-image svg {
  height: 100%;
}

.ant-table-row-expand-icon {
  outline: none;
}

.scanconnote {
  margin-bottom: 10px;
  padding: 10px;
}

.scanconnote:hover {
  background-color: #f9f9f9;
}

.text-success.ant-btn-link {
  color: #52c41a !important;
  background-color: #53c41a25 !important;
}

.text-success.ant-btn-link:hover {
  border-color: #52c41a !important;
}

.boxinfo:last-child {
  margin-bottom: 0 !important;
}

.boxinfo {
  background-color: #fff;
  margin-bottom: 10px;
}

.baginfo {
  margin-bottom: 10px;
  background-color: #fdfdfd;
}

.baginfo:last-child {
  margin-bottom: 0;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #e8e8e8;
}

.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}

.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}

.ant-descriptions-title {
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #e8e8e8;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  padding: 8px 16px;
}

.ant-descriptions-item-content {
  display: table-cell;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}

.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}

span.text-link {
  color: #021040;
  cursor: pointer;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.scale-1 {
  scale: 1.5;
}

.caret.caret-up {
  transform: rotateX(-180deg);
}

.bagnumbers {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.bagnumbers li {
  padding: 10px 5px;
  border-bottom: 1px solid #ddd;
}

.barcodewrapper canvas {
  width: 100px !important;
}

.spickupsearch {
  display: flex;
  justify-content: flex-end;
}

.spickupsearch .ant-radio-group {
  display: flex;
  margin-right: 10px;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #182343;
  border-color: #182343;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #fff;
  background: #232645;
  border-color: #232645;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active {
  color: #fff;
  background: #020311;
  border-color: #020311;
}

.spickupsearch .ant-input-affix-wrapper {
  max-width: 250px;
}

ul.asshublist {
  padding-left: 0;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

ul.asshublist li {
  padding: 8px 12px !important;
}

ul.asshublist li h5 {
  color: #70838c;
  margin-bottom: 0;
}

ul.connotelist {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

ul.connotelist li {
  margin-bottom: 10px;
  border: 1px solid #efefef;
  box-shadow: 2px 4px 4px 1px #00000014;
  padding: 15px;
}

.ant-table-small {
  border: 0;
}

.tracinglist {
  padding: 0;
}

.tracinglist li {
  list-style-type: none;
  border: 1px solid #efefef;
  padding: 10px;
  border-bottom: 0;
}

.tracinglist li:last-child {
  list-style-type: none;
  border: 1px solid #efefef;
  padding: 10px;
  border-bottom: 1px solid #efefef;
}

.tracinglist li p {
  margin-bottom: 5px;
  font-size: 13px;
}

.p-2 {
  padding: 10px;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 20px;
}

.whyus-bg {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5vh;
  height: 700px;
}

.btn-circle {
  width: 145px;
  height: 145px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  cursor: pointer;
  /* border: 2px solid red; */
}

.pos-1 {
  position: absolute;
  top: 94px;
  left: 394px;
  display: block;
}

.pos-2 {
  position: absolute;
  top: 87px;
  right: 183px;
  display: block;
}

.pos-3 {
  position: absolute;
  top: 265px;
  left: 320px;
  display: block;
}

.pos-4 {
  position: absolute;
  top: 265px;
  right: 110px;
  display: block;
}

.pos-5 {
  position: absolute;
  top: 443px;
  left: 391px;
  display: block;
}

.pos-6 {
  position: absolute;
  top: 441px;
  right: 179px;
  display: block;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }

  .pos-1 {
    position: absolute;
    top: 94px;
    left: 237px;
    display: block;
  }

  .pos-2 {
    position: absolute;
    top: 87px;
    right: 133px;
    display: block;
  }

  .pos-3 {
    position: absolute;
    top: 265px;
    left: 171px;
    display: block;
  }

  .pos-4 {
    position: absolute;
    top: 265px;
    right: 66px;
    display: block;
  }

  .pos-5 {
    position: absolute;
    top: 443px;
    left: 234px;
    display: block;
  }

  .pos-6 {
    position: absolute;
    top: 441px;
    right: 130px;
    display: block;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }

  .pos-1 {
    position: absolute;
    top: 94px;
    left: 305px;
    display: block;
  }

  .pos-2 {
    position: absolute;
    top: 87px;
    right: 150px;
    display: block;
  }

  .pos-3 {
    position: absolute;
    top: 265px;
    left: 232px;
    display: block;
  }

  .pos-4 {
    position: absolute;
    top: 265px;
    right: 74px;
    display: block;
  }

  .pos-5 {
    position: absolute;
    top: 443px;
    left: 303px;
    display: block;
  }

  .pos-6 {
    position: absolute;
    top: 441px;
    right: 147px;
    display: block;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }

  .pos-1 {
    position: absolute;
    top: 94px;
    left: 394px;
    display: block;
  }

  .pos-2 {
    position: absolute;
    top: 87px;
    right: 183px;
    display: block;
  }

  .pos-3 {
    position: absolute;
    top: 265px;
    left: 320px;
    display: block;
  }

  .pos-4 {
    position: absolute;
    top: 265px;
    right: 110px;
    display: block;
  }

  .pos-5 {
    position: absolute;
    top: 443px;
    left: 391px;
    display: block;
  }

  .pos-6 {
    position: absolute;
    top: 441px;
    right: 179px;
    display: block;
  }
}

/* iframe {
  pointer-events: none;
} */
.radius-10 {
  border-radius: 10px !important;
}

.border-info {
  border-left: 5px solid #0dcaf0 !important;
}
.border-danger {
  border-left: 5px solid #fd3550 !important;
}
.border-success {
  border-left: 5px solid #15ca20 !important;
}
.border-warning {
  border-left: 5px solid #ffc107 !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}
.bg-gradient-scooter {
  background: #17ead9;
  background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
  background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}
.widgets-icons-2 {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  font-size: 27px;
  border-radius: 10px;
}
.rounded-circle {
  border-radius: 50% !important;
}
.text-white {
  color: #fff !important;
}
.ms-auto {
  margin-left: auto !important;
}
.bg-gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.bg-gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-blooker {
  background: #ffdf40;
  background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
  background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}
.anticon svg {
  display: inline-block;
  vertical-align: baseline;
}
.ant-btn-danger {
  color: #fff;
  background-color: #f5222d;
  border-color: #f5222d;
  border-color: #f5222d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 4px #02104066, 0px 7px 13px -3px #0210404d,
    inset 0px -3px 0px #f5222d;
  transition: box-shadow 0.25s ease-in;

  font-size: 12px;
}
.mr5 .payroll-active {
  background-color: #179bd7 !important;
  border-color: #179bd7 !important;
}
.ant-btn-danger:hover,
.ant-btn-danger:active,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #f5222d;
  border-color: #f5222d;
  border-color: #f5222d;

  transition: box-shadow 0.25s ease-in;

  font-size: 12px;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.visible-none {
  visibility: hidden;
}
h3 {
  font-size: 24px;

  font-weight: 600;
}
.h5,
h5 {
  font-size: 1rem;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.bg-primary1 {
  background-color: #5bc0eb !important;
}
.bg-primary-0 {
  background-color: #005f73 !important;
}
.bg-primary-1 {
  background-color: #0fa3b1 !important;
}
.bg-primary-2 {
  background-color: #9bc53d !important;
}
.bg-primary-3 {
  background-color: #c3423f !important;
}
.bg-primary-4 {
  background-color: #404e4d !important;
}
.ant-drawer-content {
  height: 100%;
  overflow-y: scroll;
}
.c-dashboardInfo {
  margin-bottom: 15px;
}
.c-dashboardInfo .wrap {
  background: #ffffff;

  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
}
.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #ffffff;
  font-size: 1.18em;
  text-align: center;
}
.c-dashboardInfo span {
  display: block;
}
.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 24px;
  line-height: 64px;
  color: #323c43;
}
.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}
/* .ant-table-row:last-child {
  font-weight: bolder;
} */
/* .ant-table-row {   */
/* .c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
} */
/* .c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
} */
/* .c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
} */
.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}
.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
.mt-6 {
  margin-top: 1.8rem !important;
}
.mt-7 {
  margin-top: 2rem !important;
}
.icon-lg {
  width: 4.5rem;
  height: 4.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
}
.card1 {
  box-shadow: 0 20px 27px 0 rgb(0 0 / 5%);
  border: 1px solid black;
}
a {
  text-decoration: none;
}

.desc {
  color: #fff;
}
.stretched-link {
  margin-top: 20px;
}
.stretched-link {
  font-size: larger;
}
.h6 {
  margin-top: 25px;
}

.image-size {
  scale: 2.5;
}
.text-info {
  color: #108ee9 !important;
}
.btn.focus,
.btn:focus,
a.btn.focus {
  border: none !important;
  box-shadow: none !important;
}

.animate_card {
  transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    -webkit-transform 0.3s ease;
}

.animate_card:hover {
  text-decoration: none;
  transform: translateY(-6px);
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1);
}
.ant-typography code {
  color: #000;
}

.train-header {
  font-size: 16px;
  font-weight: 600;
}
.ant-tree li .ant-tree-node-content-wrapper {
  height: auto !important;
}
.ant-select-selection__clear {
  margin-top: -8px !important;
}
.anticon-close-circle svg {
  font-size: 17px !important;
  vertical-align: middle !important;
  margin-top: -2px !important;
}
a p {
  color: inherit !important;
}

.support_banner {
  background-image: url(../img/help-center_hero_banner.png);
  background-size: cover;
  background-position: center bottom;
  justify-content: center;
}

.support_banner_faq {
  background-image: url(../img/help-center_hero_banner.png);
  background-size: cover;
  background-position: right;
  justify-content: center;
}
.ql-editor {
  min-height: 18em;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
.ant-col .ant-form-item-label{
  margin-top: 10px;
}
.profile-pic
{
  max-width: 75% !important;
}
.funnel-pipeline-chart .funnel-pipeline-chart-row:last-child {
  padding-bottom: 95px !important;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper
{
  margin-left: 0px !important; 
}
.qb-lite .group--header:not(:hover) .group--actions
{
  opacity: 1 !important;
}
.query-builder
{
  margin: 0 !important;
}
.ant-tree {
  align-items:flex-start;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x:hidden;
}
/* .position-relative{
  align-items: flex-start;
  max-height: 500px;
  overflow-y: scroll;
} */
.ant-tree {
  align-items: flex-start;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x:hidden;
}